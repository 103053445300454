<template>
  <footer class="max-w-screen-xl mx-auto p-4 border-t bg-gray-50">
    <div class="mb-1">오버케이(OverK) | 사업자번호 782-65-00284</div>
    <div class="mb-1">통신판매업 신고번호 2024-서울강남-06716</div>
    <div class="mb-1">주소 서울특별시 강남구 강남대로 320 황화빌딩 1304호 | 대표자 박선오 | 유선번호 010-4120-3694</div>
    <div class="mt-4 flex text-center">
      <RouterLink :to="{ name: 'terms' }" class="block me-12">이용약관</RouterLink>
      <RouterLink :to="{ name: 'privacy' }" class="block me-12">개인정보처리방침</RouterLink>
      <RouterLink :to="{ name: 'location' }" class="block me-12">위치기반서비스 이용약관</RouterLink>
      <RouterLink :to="{ name: 'faq' }" class="block me-12">FAQ</RouterLink>
      <a class="block" style="color: green" href="https://blog.naver.com/oi" target="_blank">Naver Blog</a>
    </div>
  </footer>
</template>
