<template>
  <div class="flex bg-gray-100 rounded-t-lg justify-between mb-5">
    <RouterLink class="flex self-center space-between p-5" to="/">
      <img src="/assets/logo.png" alt="Logo" class="h-8 mr-3 wrap" />
    </RouterLink>
    <!-- <div class="border border-gray-300 rounded-lg p-5 me-5">
      <LoginFrom />
    </div> -->
  </div>
</template>

<!-- <script setup lang="ts">
import LoginFrom from './LoginForm.vue'
</script> -->
